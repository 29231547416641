import {
  getCurrentInstance,
  ref, watch,
} from '@vue/composition-api';
import store from '@/store';
import toast from '@/utils/toast';
import { isEnableLocationModule } from '@/auth/utils';
import { useUtils as useI18nUtils } from '@core/libs/i18n';
import moment from 'moment';
import reportPropertyModule from '../reportPropertyModule';

export default function useReportSpecialContract() {
  const ROOM_STORE_MODULE_NAME = 'report-special-contract';

  // Register module
  if (!store.hasModule(ROOM_STORE_MODULE_NAME)) {
    store.registerModule(ROOM_STORE_MODULE_NAME, reportPropertyModule);
  }

  // Use toast
  const toastification = toast();
  const { t } = useI18nUtils();
  const blankItem = {
    id: 0,
    apartment: null,
    floor: null,
    name: '',
    price: null,
    deposit: null,
    size: null,
    active: true,
  };

  const vm = getCurrentInstance().proxy

  const item = ref(JSON.parse(JSON.stringify(blankItem)));
  const resetItem = () => {
    item.value = JSON.parse(JSON.stringify(blankItem));
  };
  const rows = ref([]);

  // Table Handlers
  const columns = [
    {
      label: t('Tháng'),
      field: 'month',
    },
    {
      label: t('Tòa nhà'),
      field: 'apartment.name',
    },
    {
      label: t('Căn hộ'),
      field: 'room.name',
    },
    {
      label: t('Giá hiện tại'),
      field: 'price',
    },
    {
      label: t('Giá niêm yết'),
      field: 'oldPrice',
    },
    {
      label: t('Ngày bắt đầu tính tiền'),
      field: 'billingDate',
    },
    {
      label: t('Ngày bắt đầu'),
      field: 'startDate',
    },
    {
      label: t('Ngày kết thúc'),
      field: 'endDate',
    },
    {
      label: t('Số tháng hợp đồng'),
      field: 'contractMonth',
    },
    {
      label: t('Số tháng KM'),
      field: 'promotionMonth',
      type: 'number',
    },
    {
      label: t('Số tiền KM'),
      field: 'promotionPricePerMonth',
      type: 'number',
    },
    {
      label: t('Nhân viên thao tác'),
      field: 'creator',
      type: 'number',
    },
    {
      label: t('Loại hợp đồng'),
      field: 'contractType',
    },

  ];

  // filter
  const apartment = ref(null);
  const location = ref(null);
  const month = ref(moment().format("MM/YYYY"));
  // current selected rows
  const selectedRows = ref([]);
  // ssr
  const isLoading = ref(false);
  const totalRecords = ref(0);
  const serverParams = ref({
    searchTerm: '',
    filter: { month: moment().format("MM/YYYY") },
    sort: {},
    page: 1,
    perPage: 20,
  });

  //   API Call
  const fetchRooms = () => {
    isLoading.value = true;
    store
      .dispatch('report-special-contract/fetchSpecialContracts', serverParams.value)
      .then(response => {
        totalRecords.value = response.data.length;
        rows.value = response.data;
        isLoading.value = false;
      })
      .catch(error => {
        toastification.showToastError(error);
        isLoading.value = false;
      });
  };

  const fetchData = () => {
    fetchRooms();
  };

  const exportData = () => {
    store
      .dispatch('report-special-contract/exportSpecialContracts', serverParams.value)
      .then(response => {
        if (response.data === true) {
          vm.$router.push({ name: 'jobs' });
        } else {
          window.location.href = response.data;
        }
      })
      .catch(error => {
        toastification.showToastError(error);
      });
  };

  //   Methods
  const onViewDetailItem = val => {
    item.value = val;
  };
  const updateParams = newProps => {
    serverParams.value = { ...serverParams.value, ...newProps };
    fetchData();
  };

  const onPageChange = params => {
    updateParams({ page: params.currentPage });
  };

  const onPerPageChange = params => {
    updateParams({ perPage: params.currentPerPage });
  };

  const onSortChange = params => {
    updateParams({
      sort: {
        type: params[0].type,
        field: params[0].field,
      },
    });
  };

  const onColumnFilter = params => {
    updateParams(params);
  };

  const selectionChanged = params => {
    selectedRows.value = params.selectedRows;
  };

  const contractMonth = contract => {
    if (moment(contract.startDate).isValid() && moment(contract.endDate).isValid()) {
      return Math.abs(moment(contract.startDate).diff(moment(contract.endDate), 'months')) + 1;
    }
    return 0;
  };

  const contractType = contract => {
    if (contract.type === 'extend') {
      return "Hợp đồng gia hạn";
    }
    if (contract.type === 'move') {
      return "Hợp đồng chuyển";
    }
    if (contract.type === 'transfer') {
      return "Hợp đồng nhượng";
    }
    return "";
  };

  //   Watch
  watch(apartment, val => {
    const { filter } = serverParams.value;
    if (val) {
      filter.apartmentId = val.id;
    } else {
      delete filter.apartmentId;
    }
    updateParams({ filter });
  });
  watch(location, val => {
    const { filter } = serverParams.value;
    if (val) {
      filter.locationId = val.id;
    } else {
      delete filter.locationId;
    }
    updateParams({ filter });
  });
  watch(month, val => {
    const { filter } = serverParams.value;
    if (val) {
      filter.month = month.value;
    } else {
      delete filter.month;
    }
    updateParams({ filter });
  });

  return {
    item,
    columns,
    rows,
    apartment,
    month,
    selectedRows,
    isLoading,
    totalRecords,
    serverParams,
    location,
    isEnableLocationModule,
    fetchData,
    fetchRooms,
    resetItem,
    updateParams,
    onPageChange,
    onPerPageChange,
    onSortChange,
    onColumnFilter,
    selectionChanged,
    onViewDetailItem,
    exportData,
    t,
    contractMonth,
    contractType,
  };
}
